<template>
    <div class="container">
        <el-form :model="dataForm" ref="dataForm" label-position="top">
            <el-form-item>
                <el-switch
                    v-model="dataForm.enable"
                    active-text="启用短信插件"
                    inactive-text="关闭短信插件"
                    :active-value="1"
                    :inactive-value="0">
                </el-switch>
            </el-form-item>
            <template v-if="dataForm.enable === 1">
                <el-form-item label="短信服务商">
                    <el-radio-group v-model="dataForm.engine">
                        <el-radio label="aliyun">阿里云</el-radio>
                        <el-radio label="tencent">腾讯云</el-radio>
                    </el-radio-group>
                </el-form-item>
                <template v-if="dataForm.engine === 'aliyun'">
                    <el-form-item label="AccessKey ID">
                        <el-input v-model="dataForm.aliyun.accessKeyId" placeholder="阿里云短信 AccessKey ID" clearable/>
                    </el-form-item>
                    <el-form-item label="AccessKey Secret">
                        <el-input v-model="dataForm.aliyun.accessKeySecret" placeholder="阿里云短信 AccessKey Secret"
                                  clearable/>
                    </el-form-item>
                    <el-form-item label="短信签名">
                        <el-input v-model="dataForm.aliyun.sign" placeholder="阿里云短信签名" clearable/>
                    </el-form-item>
                </template>
                <template v-else-if="dataForm.engine === 'tencent'">
                    <el-form-item label="AppId">
                        <el-input v-model="dataForm.tencent.appId" placeholder="腾讯云应用App Id" clearable/>
                    </el-form-item>
                    <el-form-item label="SecretId">
                        <el-input v-model="dataForm.tencent.secretId" placeholder="腾讯云短信 Secret Id" clearable/>
                    </el-form-item>
                    <el-form-item label="SecretKey">
                        <el-input v-model="dataForm.tencent.secretKey" placeholder="腾讯云短信 Secret Key" clearable/>
                    </el-form-item>
                    <el-form-item label="短信签名">
                        <el-input v-model="dataForm.tencent.sign" placeholder="腾讯云短信签名" clearable/>
                    </el-form-item>
                </template>
            </template>
            <el-form-item>
                <el-button v-if="$perm('resource_sms_update')" @click="doSave" :loading="loading" type="primary">保 存
                    配 置
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
export default {
    name: "Setting",
    data: function () {
        return {
            loading: false,
            dataForm: {
                engine: 'aliyun',
                enable: 0,
                aliyun: {
                    accessKeyId: null,
                    accessKeySecret: null,
                    sign: null
                },
                tencent: {
                    appId: null,
                    secretId: null,
                    secretKey: null,
                    sign: null
                }
            }
        }
    },
    activated: function () {
        this.getConfig()
    },
    methods: {
        getConfig: function () {
            this.$helper._get(this, 'resource.sms.config', {}, resp => {
                this.dataForm = resp.data
            })
        },
        doSave: function () {
            this.$helper._post(this, 'resource.sms.update', {setting: JSON.stringify(this.dataForm)}, resp => {
                this.$message.success(resp.message)
            })
        }
    }
}
</script>

<style scoped>

</style>
